import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/contentful/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/contentful/logo-grid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/contentful/section.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/carousel1.tsx");
